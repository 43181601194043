import React, { useRef, useContext } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import Upload from "../../assets/upload.svg";
import { GlobalState } from "../../data/Context";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import { MainBtn } from "../button";

const Inputs = () => {
	return <div></div>;
};
export const SearchInput = () => {
	return (
		<div
			style={{
				border: "0.5px solid rgba(37, 37, 37, 0.50",
			}}
			className="tw-relative tw-h-10 lg:tw-w-80 tw-w-48 tw-bg-transparent tw-rounded-full">
			<input
				type="text"
				placeholder="Search"
				className="tw-rounded-full tw-pl-11 tw-h-full tw-w-full smallText"
			/>
			<div className="tw-absolute tw-top-3 tw-cursor-pointer tw-left-4">
				<AiOutlineSearch />
			</div>
		</div>
	);
};

export const TextInput = ({
	label,
	placeholder,
	type,
	onChange,
	name,
	value,
	setState,
	...rest
}) => {
	const { nairaSignNeutral } = useContext(GlobalState);
	return (
		<div>
			<p className="tw-text-base tw-font-medium satoshi tw-text-secondary">
				{label}
			</p>
			{["number", "tel"]?.includes(type) ? (
				<NumericFormat
					prefix={
						rest?.noFormat
							? ""
							: !rest?.percentage
							? `${nairaSignNeutral} `
							: ""
					}
					suffix={rest?.noFormat ? "" : rest?.percentage ? ` %` : ""}
					className="tw-h-12 tw-w-full tw-border tw-border-secondary tw-pl-6 tw-rounded-lg smallText"
					value={value}
					placeholder={rest?.noFormat ? "2" : rest?.percentage ? "2%" : "2,000"}
					displayType="input"
					thousandSeparator={true}
					onValueChange={val => setState(val?.floatValue)}
					min={0}
					inputMode={"decimal"}
					renderText={(value, props) => <span {...props}>{value}</span>}
					allowNegative={false}
				/>
			) : (
				<input
					type={type || "text"}
					name={name}
					value={value}
					placeholder={placeholder}
					onChange={onChange}
					className="tw-h-12 tw-w-full tw-border tw-border-secondary tw-pl-6 tw-rounded-lg smallText"
					{...rest}
				/>
			)}
		</div>
	);
};

export const TextArea = ({
	label,
	onChange,
	name,
	placeholder,
	value,
	...rest
}) => {
	return (
		<div>
			<p className="tw-text-base tw-font-medium satoshi tw-text-secondary">
				{label}
			</p>
			<textarea
				name={name}
				placeholder={placeholder}
				onChange={onChange}
				value={value}
				className="tw-h-72 tw-w-full tw-p-5 tw-border tw-border-secondary tw-rounded-xl smallText"
				id=""
				cols="30"
				rows="10"
				{...rest}
			/>
		</div>
	);
};

export const OptionsInput = ({
	label,
	placeholder,
	onChange,
	name,
	index,
	question,
	option,
	handleInputChangeForMutipleItem,
}) => {
	return (
		<div>
			<p className="tw-text-base tw-font-medium satoshi tw-text-secondary">
				{label}
			</p>
			<div className="tw-h-12 tw-w-full tw-border tw-border-secondary tw-rounded-lg tw-relative">
				<input
					type="text"
					name=""
					placeholder={placeholder}
					className="tw-w-full tw-h-full tw-pl-4 smallText"
					id=""
					value={question?.[option]}
					onChange={event =>
						handleInputChangeForMutipleItem(event, index, option)
					}
				/>
				<div className="tw-flex tw-gap-2 tw-items-center tw-justify-center tw-absolute tw-top-4 tw-right-4">
					<small className="tw-text-xs tw-text-skyblue tw-font-normal">
						Correct answer
					</small>
					<input
						className="tw-border tw-border-secondary"
						type="radio"
						name={`answer${index}`}
						value={question?.answer}
						checked={question?.answer === option}
						onChange={event =>
							handleInputChangeForMutipleItem(event, index, "answer", option)
						}
					/>
				</div>
			</div>
		</div>
	);
};

export const VideoInput = ({
	name,
	onChange,
	state,
	setState,
	ty,
	...rest
}) => {
	const ref = useRef();
	const handleClick = () => {
		ref.current?.click();
	};

	let handleChangeImage = e => {
		const file = e?.target.files[0];
		let err = "";
		console.log({ err, file }, "one");
		if (!file) err = `File, ${file?.name} does not exist`;
		if (!file.type.includes(ty || "image"))
			err = `File, ${file?.name} format not supported`;
		console.log({ err, file });
		if (err) {
			return toast.error(err);
		} else {
			setState({ ...state, [name || "image"]: file });
		}
	};
	// console.log({ [name]: state?.[name], state });
	return (
		<div>
			{!rest?.noTitle && (
				<p className="tw-text-base tw-font-medium satoshi tw-text-secondary">
					Choose Video
				</p>
			)}
			{state && state?.[name] && typeof state?.[name] !== "string" ? (
				<>
					<div className="tw-tounded-4xl tw-border tw-border-dashed tw-h-2/4 tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-gray tw-w-full file_upload">
						{state && state?.[name] ? (
							state && state?.[name]?.type?.includes("video") ? (
								<video
									src={
										state?.[name]?.playback_url &&
										typeof state?.[name]?.playback_url === "string"
											? state?.[name]?.playback_url
											: state?.[name]?.url &&
											  typeof state?.[name]?.url === "string"
											? state?.[name]?.url
											: URL.createObjectURL(state?.[name])
									}
									controls
									alt="Course"
									className="img-fluid tw-w-full tw-h-full"
									style={{
										objectFit: "contain",
									}}
								/>
							) : (
								<img
									src={
										state?.[name]?.url && typeof state?.[name]?.url === "string"
											? state?.[name]?.url
											: URL.createObjectURL(state?.[name])
									}
									alt="Course"
									className="img-fluid tw-w-full tw-h-full"
									style={{
										objectFit: "contain",
									}}
								/>
							)
						) : null}
					</div>
					{!rest?.noTitle && (
						<div className="tw-flex tw-gap-5 tw-justify-end tw-items-center tw-py-3">
							<MainBtn onClick={handleClick} text={`Upload New`} />
						</div>
					)}
				</>
			) : (
				<div
					onClick={handleClick}
					className="tw-h-24 tw-cursor-pointer tw-rounded-xl tw-border-secondary tw-border tw-w-full tw-flex tw-justify-center tw-items-center">
					<div className="">
						<img src={Upload} alt="" className="tw-mx-auto" />
						<small className="tw-text-sm tw-text-[#275A7F] tw-font-medium satoshi tw-text-center">
							Click to upload
						</small>
						<h6 className="tw-text-xs tw-text-[#275A7F] tw-font-medium satoshi tw-text-center">
							(mp4, mpeg)
						</h6>
					</div>
				</div>
			)}
			<input
				type="file"
				title="Upload file"
				id="file"
				name={name}
				onChange={handleChangeImage}
				ref={ref}
				className="tw-hidden"
				accept={rest?.accept}
			/>
		</div>
	);
};
export default Inputs;
