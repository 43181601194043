import React, { useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import { useNavigate } from "react-router-dom";

const GroupsTable = () => {
  const [active, setActive] = useState(null);
  const navigate = useNavigate();
  const Headers = [
      "Group Name",
      "Number Of Members",
      "Instructors",
      "Date Created",
      "Action",
    ],
    currentItems = [
      {
        name: "Leader G",
        no_of_members: 42,
        instructors: 5,
        date: "07 May 1022",
      },
    ],
    handleActive = (i) => {
      if (active === i) {
        setActive(null);
      } else {
        setActive(i);
      }
    },
    actions = [
      {
        name: "view group",
        action: () => navigate(`/groups/${active}`),
      },
      {
        name: "assign course",
      },
      {
        name: "share document",
      },
      {
        name: "edit group",
      },
      {
        name: "delete group",
      },
    ];
  return (
    <div>
      <div className="">
        <table className="tw-min-w-full">
          <thead>
            <tr>
              {Headers?.filter((it) => it)?.map((item) => (
                <th className="tw-text-secondary tw-p-3 tw-font-medium tw-text-left satoshi tw-text-sm tw-capitalize">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((item, i) => (
              <tr
                style={{
                  borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
                }}
                key={i}
              >
                <td className="tableText">{item?.name}</td>
                <td className="tableText tw-capitalize">
                  {item?.no_of_members}
                </td>
                <td className="tableText">{item?.instructors}</td>
                <td className="tableText">{item?.date}</td>
                <td>
                  <div className="tw-relative">
                    <div className="tw-h-6 tw-ml-7 tw-w-3 tw-rounded-xl tw-flex tw-justify-center tw-items-center tw-border tw-border-[#87C3FF]">
                      <SlOptionsVertical
                        onClick={() => handleActive(i)}
                        className="tw-cursor-pointer"
                        color="#87C3FF"
                      />
                    </div>
                    {active === i && (
                      <div className="tw-absolute tw-z-50 tw-rounded tw-bg-white tw-w-36 tw-p-2 tw-top-8 tw-left-[-5rem]">
                        {actions?.map((act) => (
                          <button
                            onClick={act?.action}
                            style={{
                              borderBottom: "0.5px solid #00000033",
                            }}
                            className="tw-h-8 tw-w-full tw-text-left tw-capitalize tw-text-sm tw-font-normal tw-text-secondary"
                          >
                            {act?.name}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* {pageCount > 1 && (
          <MainPaginate
            pageCount={pageCount}
            handlePageClick={handlePageClick}
          />
        )} */}
      </div>
    </div>
  );
};

export const MembersTable = () => {
  const [active, setActive] = useState(null),
    navigate = useNavigate();
  const headers = [
      "name",
      "department",
      "date added",
      "progress",
      "status",
      "action",
    ],
    data = [
      {
        name: "Ajayi Henry",
        department: "Safety Unit",
        date: "02 May, 2022",
        progress: "100%",
        status: "completed",
      },
      {
        name: "Ajayi Henry",
        department: "Safety Unit",
        date: "02 May, 2022",
        progress: "100%",
        status: "completed",
      },
      {
        name: "Ajayi Henry",
        department: "Safety Unit",
        date: "02 May, 2022",
        progress: "100%",
        status: "completed",
      },
      {
        name: "Ajayi Henry",
        department: "Safety Unit",
        date: "02 May, 2022",
        progress: "100%",
        status: "progress",
      },
    ],
    handleActive = (i) => {
      if (active === i) {
        setActive(null);
      } else {
        setActive(i);
      }
    },
    actions = [
      {
        name: "view member",
        action: () => navigate(`/student/${active}`),
      },
      {
        name: "add member",
      },
    ];
  return (
    <div>
      <div className="">
        <table className="tw-min-w-full">
          <thead>
            <tr>
              {headers
                ?.filter((it) => it)
                ?.map((item) => (
                  <th className="tw-text-secondary tw-p-3 tw-font-medium tw-text-left satoshi tw-text-sm tw-capitalize">
                    {item}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, i) => (
              <tr
                style={{
                  borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
                }}
                key={i}
              >
                <td className="tableText">{item?.name}</td>
                <td className="tableText tw-capitalize">{item?.department}</td>
                <td className="tableText">{item?.date}</td>
                <td className="tableText">{item?.progress}</td>
                <td
                  style={{
                    color: item?.status === "completed" ? "#0B6C25" : "#2100A5",
                  }}
                  className="tableText tw-capitalize"
                >
                  {item?.status}
                </td>
                <td>
                  <div className="tw-relative">
                    <div className="tw-h-6 tw-ml-7 tw-w-3 tw-rounded-xl tw-flex tw-justify-center tw-items-center tw-border tw-border-[#87C3FF]">
                      <SlOptionsVertical
                        onClick={() => handleActive(i)}
                        className="tw-cursor-pointer"
                        color="#87C3FF"
                      />
                    </div>
                    {active === i && (
                      <div className="tw-absolute tw-z-50 tw-rounded tw-bg-white tw-w-36 tw-p-2 tw-top-8 tw-left-[-5rem]">
                        {actions?.map((act) => (
                          <button
                            onClick={act?.action}
                            style={{
                              borderBottom: "0.5px solid #00000033",
                            }}
                            className="tw-h-8 tw-w-full tw-text-left tw-capitalize tw-text-sm tw-font-normal tw-text-secondary"
                          >
                            {act?.name}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* {pageCount > 1 && (
      <MainPaginate
        pageCount={pageCount}
        handlePageClick={handlePageClick}
      />
    )} */}
      </div>
    </div>
  );
};

export const InstructorsTable = () => {
  const [active, setActive] = useState(null),
    navigate = useNavigate();
  const headers = ["name", "department", "date added", "action"],
    data = [
      {
        name: "Ajayi Henry",
        department: "Safety Unit",
        date: "02 May, 2022",
      },
      {
        name: "Ajayi Henry",
        department: "Safety Unit",
        date: "02 May, 2022",
      },
      {
        name: "Ajayi Henry",
        department: "Safety Unit",
        date: "02 May, 2022",
      },
      {
        name: "Ajayi Henry",
        department: "Safety Unit",
        date: "02 May, 2022",
      },
    ],
    handleActive = (i) => {
      if (active === i) {
        setActive(null);
      } else {
        setActive(i);
      }
    },
    actions = [
      {
        name: "view member",
        action: () => navigate(`/student/${active}`),
      },
      {
        name: "add member",
      },
    ];
  return (
    <div>
      <div className="">
        <table className="tw-min-w-full">
          <thead>
            <tr>
              {headers
                ?.filter((it) => it)
                ?.map((item) => (
                  <th className="tw-text-secondary tw-p-3 tw-font-medium tw-text-left satoshi tw-text-sm tw-capitalize">
                    {item}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, i) => (
              <tr
                style={{
                  borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
                }}
                key={i}
              >
                <td className="tableText">{item?.name}</td>
                <td className="tableText tw-capitalize">{item?.department}</td>
                <td className="tableText">{item?.date}</td>
                <td>
                  <div className="tw-relative">
                    <div className="tw-h-6 tw-ml-7 tw-w-3 tw-rounded-xl tw-flex tw-justify-center tw-items-center tw-border tw-border-[#87C3FF]">
                      <SlOptionsVertical
                        onClick={() => handleActive(i)}
                        className="tw-cursor-pointer"
                        color="#87C3FF"
                      />
                    </div>
                    {active === i && (
                      <div className="tw-absolute tw-z-50 tw-rounded tw-bg-white tw-w-36 tw-p-2 tw-top-8 tw-left-[-5rem]">
                        {actions?.map((act) => (
                          <button
                            onClick={act?.action}
                            style={{
                              borderBottom: "0.5px solid #00000033",
                            }}
                            className="tw-h-8 tw-w-full tw-text-left tw-capitalize tw-text-sm tw-font-normal tw-text-secondary"
                          >
                            {act?.name}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GroupsTable;
