import React, { useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { Button } from "../../components/button";
import {
  InstructorsTable,
  MembersTable,
} from "../../components/tables/groupstable";
import AddMemberModal, {
  AddInstructorModal,
} from "../../components/modals/addmembermodal";

const GroupDetails = () => {
  const [tab, setTab] = useState("members"),
    [modal, setModal] = useState(""),
    tabs = ["members", "instructors"];
  return (
    <div>
      <MainContainer>
        <MainHeader text={"All Groups"} small={"334 Groups"} />
        <div className="tw-bg-white tw-mt-8 tw-rounded-xl noScroll tw-p-6">
          <h5 className="tw-text-xl tw-font-bold tw-text-secondary">
            Leader G.
          </h5>
          <div className="tw-mt-6 tw-flex tw-justify-between tw-items-center">
            <div className="tw-flex tw-gap-6">
              {tabs?.map((t) => (
                <button
                  onClick={() => setTab(t)}
                  className={`tw-h-10 tw-px-4 tw-rounded-full tw-text-[#0269D0] tw-text-base tw-font-medium satoshi tw-capitalize ${
                    tab === t ? "tw-bg-[#D9ECFF]" : "tw-bg-transparent"
                  }`}
                >
                  {t}
                </button>
              ))}
            </div>
            <div className="tw-flex tw-gap-5 tw-items-center">
              <strong className="tw-text-base tw-font-medium tw-text-[#E34033] satoshi tw-border-b tw-border-b-[#E34033]">
                Delete Group
              </strong>
              {tab === "members" ? (
                <button
                  onClick={() => setModal("add-member")}
                  className="tw-h-10 tw-px-4 tw-rounded-full tw-text-[#0269D0] tw-text-base tw-font-medium satoshi tw-capitalize tw-bg-[#D9ECFF]"
                >
                  Add Member
                </button>
              ) : (
                <button
                  onClick={() => setModal("add-instructor")}
                  className="tw-h-10 tw-px-4 tw-rounded-full tw-text-[#0269D0] tw-text-base tw-font-medium satoshi tw-capitalize tw-bg-[#D9ECFF]"
                >
                  Add Instructor
                </button>
              )}
              <Button>Export</Button>
            </div>
          </div>
          <div className="tw-mt-6">
            {tab === "members" ? <MembersTable /> : <InstructorsTable />}
          </div>
        </div>
      </MainContainer>
      {modal === "add-member" && (
        <AddMemberModal handleClose={() => setModal("")} />
      )}
      {modal === "add-instructor" && (
        <AddInstructorModal handleClose={() => setModal("")} />
      )}
    </div>
  );
};

export default GroupDetails;
