import React from "react";
import { TextInput } from "../inputs";
import { MainBtn } from "../button";

const CreateGroupModal = ({ handleClose }) => {
  return (
    <div>
      <div
        onClick={(e) => e.target === e.currentTarget && handleClose()}
        className="tw-fixed tw-z-50 tw-inset-0 tw-bg-black tw-bg-opacity-20 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center"
      >
        <div className="lg:tw-w-1/2 tw-w-5/6 tw-mx-auto tw-p-6 tw-bg-[#fff] tw-rounded-xl tw-h-1/2 noScroll tw-overflow-y-auto">
          <div className="tw-bg-white tw-rounded-xl tw-w-full tw-p-5">
            <h1 className="tw-text-xl tw-font-medium satoshi tw-text-secondary">
              Create Group
            </h1>
            <form
              action=""
              onSubmit={(e) => e?.preventDefault()}
              className="tw-mt-5"
            >
              <div className="tw-space-y-4">
                <TextInput label={"Name of Group"} />
                <TextInput label={"Add Supervisor"} />
                <TextInput label={"Add Member"} />
              </div>
              <div className="tw-mt-6">
                <MainBtn text={"Create Group"} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ModalContainer = ({ handleClose, children }) => {
  return (
    <div
      onClick={(e) => e.target === e.currentTarget && handleClose()}
      className="tw-fixed tw-z-50 tw-inset-0 tw-bg-myblue tw-bg-opacity-20 tw-flex tw-justify-center tw-h-screen tw-w-full tw-items-center"
    >
      <div className="lg:tw-w-1/2 tw-w-5/6 tw-mx-auto tw-p-6 tw-bg-[#fff] tw-max-h-96 tw-rounded-xl  noScroll tw-overflow-y-auto">
        {children}
      </div>
    </div>
  );
};

export default CreateGroupModal;
