import React, { useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { SearchInput } from "../../components/inputs";
import { MainBtn } from "../../components/button";
import GroupsTable from "../../components/tables/groupstable";
import CreateGroupModal from "../../components/modals/creategroupmodal";

const Groups = () => {
  const [modal, setModal] = useState("");
  return (
    <div>
      <MainContainer>
        <MainHeader text={"All Groups"} small={"334 Groups"} />
        <div className="tw-bg-white tw-mt-8 tw-rounded-xl noScroll tw-p-6">
          <div className="tw-flex tw-justify-between tw-items-center">
            <div className="tw-flex tw-gap-6 tw-items-center">
              <h5 className="tw-text-xl tw-font-bold tw-text-secondary">
                All Groups
              </h5>
              <SearchInput />
            </div>
            <MainBtn onClick={() => setModal("create")} text={"Create Group"} />
          </div>
          <div className="tw-mt-8">
            <GroupsTable />
          </div>
        </div>
      </MainContainer>
      {modal === "create" && (
        <CreateGroupModal handleClose={() => setModal("")} />
      )}
    </div>
  );
};

export default Groups;
